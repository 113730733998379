import { useCpaEntityQuery } from 'hooks/use-cpa-entity'
import { useThankyouData } from 'hooks/use-thankyou-data'
import { ChatActivate } from '../components/Chat/ChatActivate'
import { A2ADataContextProvider } from '../contexts/A2ADataContext'
import { LayoutContextProvider } from '../contexts/LayoutContext'
import { WebformMaggiorTutela } from 'components/WebformMaggiorTutela/WebformMaggiorTutela'
import 'style.css'
import { Helmet } from 'react-helmet'
import { useEffect } from 'react'

const MaggiorTutelaPage = () => {
  const pageTitle = 'Servizio di Maggior Tutela'
  const thankyouData = useThankyouData()
  const chatQuery = useCpaEntityQuery()

  const cookieScript = `var _iub = _iub || [];\n_iub.csConfiguration = {"askConsentAtCookiePolicyUpdate":true,"consentOnContinuedBrowsing":false,"countryDetection":true,"enableCcpa":true,"enableRemoteConsent":true,"inlineDelay":500,"invalidateConsentWithoutLog":true,"logLevel":"info","perPurposeConsent":true,"purposes":"1,2,4,3,5","siteId":2085692,"cookiePolicyId":77745020,"googleConsentMode":false,"lang":"it","cookiePolicyUrl":"https://maggiortutela.a2a.it/varie/policyEmbed.html","privacyPolicyUrl":"https://maggiortutela.a2a.it/varie/policyEmbed.html", "banner":{ "acceptButtonCaptionColor":"#ffffff","acceptButtonColor":"#ffa403","acceptButtonDisplay":true,"backgroundColor":"#e6f3f8","closeButtonRejects":true,"customizeButtonCaptionColor":"#ffa403","customizeButtonColor":"#e6f3f8","customizeButtonDisplay":true,"explicitWithdrawal":true,"listPurposes":true,"position":"float-bottom-center","textColor":"#020000","content":"<div id='iubenda-cs-title'>Informativa</div><br><div id='iubenda-cs-paragraph'>Questo sito utilizza sia cookie tecnici necessari al funzionamento dello stesso ed all’erogazione dei relativi servizi sia cookie analitici di terza parte per l’elaborazione di statistiche aggregate; per tali cookie non è richiesto il tuo consenso.<br>Potrebbero, inoltre, essere installati cookie di profilazione di terze parti per inviarti pubblicità in linea con le tue preferenze; per accettare l’utilizzo di questi cookie clicca su “Accetta”, mentre cliccando sul tasto “X” (collocata all’interno di questo banner, in alto a destra) precludi la loro installazione; se, invece, vuoi operare una scelta analitica su specifici cookie clicca su “Scopri di più e personalizza”.<br>Se vuoi ricevere maggiori informazioni clicca sulla <a href='https://maggiortutela.a2a.it/varie/policyEmbed.html' class='iubenda-cs-cookie-policy-lnk' target='_blank' style='color: #009cde!important' rel='noopener'>privacy policy</a>.</div>" }};`

  useEffect(() => {
    const script = document.createElement('script'),
      script2 = document.createElement('script'),
      script3 = document.createElement('script')

    script.setAttribute('type', 'text/javascript')
    script.innerHTML = cookieScript

    script2.setAttribute('type', 'text/javascript')
    script2.setAttribute('src', '//cdn.iubenda.com/cs/ccpa/stub.js')

    script3.setAttribute('type', 'text/javascript')
    script3.setAttribute('src', '//cdn.iubenda.com/cs/iubenda_cs.js')
    script3.setAttribute('charset', 'UTF-8')
    script3.setAttribute('async', 'true')
    document.body.appendChild(script)
    document.body.appendChild(script2)
    document.body.appendChild(script3)
  }, [])

  return (
    <A2ADataContextProvider>
      <LayoutContextProvider>
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <ChatActivate chatQuery={chatQuery} />
        <WebformMaggiorTutela
          thankyouData={thankyouData}
        ></WebformMaggiorTutela>
      </LayoutContextProvider>
    </A2ADataContextProvider>
  )
}

export default MaggiorTutelaPage
